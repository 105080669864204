.navbar-brand {
  font-weight: bold;
}

.navbar {
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

.experiencediv {
  background-color: #f5f5f5;
  padding: 20px;
}

.profileimage {
  width: 50%;
  float: right;
}


.profile {
  width: 50%;
}

.about-me {
  background-color: #fafafa;
  font-family: "Montserrat", sans-serif;
  padding: 60px;
  padding-top: 85px;
  display: flex;
  justify-content: center;
}

#title {
  text-align: center;
  overflow: hidden;
  width: 100%;
  font-size: 50px;
  padding-bottom: 10px;
  font-weight: 300;
}


.description {
  margin-right: auto;
  font-size: 16px;
  line-height: 1cm;
}

.profile {
  margin-left: 70px;
}

.name {
  padding-top: 20px;
  font-size: 38px;
}

.image {
  height: 525px;
  width: 525px;
  float: right;
}

.role {
  font-weight: 400;
  font-size: 12px;
  color: #bebebe
}

.icons {
  padding-bottom: 8px;
  padding-left: 2px;
}

.mail-image:hover {
  transform: scale(1.05);
}

.github-image:hover {
  transform: scale(1.05);
}

.linkedin-image:hover {
  transform: scale(1.05);
}

img.linkedin-image{
  height: 24px;
}

.info_box {
  font-family: Arial, sans-serif;
  display: grid;
  width: 142px;
  transition: all .2s ease-in-out;
  border: 1px solid rgb(193, 193, 193);
  border-radius: 4px;
}

.info_box:hover {
  transform: scale(1.05);
}

.top_box {
  border-top-left-radius: 3.28px;
  border-top-right-radius: 3.28px;
  width: 142px;
  height: 18px;
  background-color: #003366;
}

.middle_box {
  width: 142px;
  height: 120px;
}

.middle_box_text {
  font-size: 14px;
  text-decoration: none;
  position: relative;
  top: 15px;
  left: 32.5px;
}

.sizeProfile {
  width: 82%;
}

.bottom_box {
  width: 142px;
  height: 20px;
  border-top: 1px solid rgb(193, 193, 193);
}

#award, #experience{
  scroll-margin-top: 50px;
}

.bottom_box_text {
  font-size: 10px;
  position: relative;
  text-decoration: none;
  left: 27px;
  bottom: 3px;
}

.header {
  font-weight: 300;
  font-size: 50px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
  background-color: #fafafa;
}

.date {
  font-style: italic;
  position: absolute;
  bottom: 10px;
}

.bodys {
  font-size: 22px;
}

.awards {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 48px;
  align-items: center;
  background-color: #fafafa;
}

.boxs {
  height: 270px;
  width: 18%;
  border-radius: 5px;
  color: #fff;
  background-color: #676767;
  padding: 20px;
  position: relative;
  box-sizing: unset;
}

.images {
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  width: 50px;
  height: 50px;
  background-position: center center;
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 1200px) {
  .awards {
    flex-wrap: wrap;
  }

  .boxs {
    width: 30%;
    margin-bottom: 20px;
  }

  .about-me {
    flex-direction: column;
    align-items: center;
  }

  .divider {
    width: 0%;
  }

  .image {
    width: 300px;
    height: 300px;
    float: none;
  }

  .profileimage {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    width: unset;
  }

  .profile {
    padding: 20px;
    width: 86%;
    margin-left: 0px;
    text-align: center;
    margin-top: -74px;
  }

  .about-me {
    padding-top: 85px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .name {
    font-size: 28px;
  }

  .role {
    font-size: 11px;
  }

  .sizeProfile {
    width: unset;
  }

}

@media (min-width:400px) and (max-width:900px) {
  .boxs {
    width: 50%;
  }
}

@media (min-width:1885px) and (max-width:5000px) {
    .sizeProfile{
      width: 64%;
    }
}

@media (min-width:1737px) and (max-width:1884px) {
  .sizeProfile{
    width: 70%;
  }
}

@media (min-width:1561px) and (max-width:1736px) {
  .sizeProfile{
    width: 79%;
  }
}

@media (min-width:1419px) and (max-width:1560px) {
  .sizeProfile{
    width: 88%;
  }
}

@media (min-width:1369px) and (max-width:1560px) {
  .sizeProfile{
    width: 92%;
  }
}

@media (min-width:1259px) and (max-width:1368px) {
  .sizeProfile{
    width: 101%;
  }
}

@media screen and (max-width: 900px) {
  .boxs {
    width: 70%;
  }
}

@media (min-width:1200px) and (max-width: 1800px) {
  .boxs {
    width: 25%;
    margin-bottom: 20px;
  }

  .awards {
    flex-wrap: wrap;
  }
}